/*! (c) LavaImagem */

@import '../../bootstrap/css/bootstrap.min.css';
@import '../../template/css/slicknav.css';
@import '../../template/css/style.css';
@import '../../template/css/custom_events_list.css';
@import '../../template/css/custom_entitys_list.css';
@import '../../template/css/custom_addresses_list.css';
@import '../../template/css/responsive.css';
@import '../../template/css/animate.css';
@import '../../template/css/component.css';
@import '../../template/css/colors/green.css';
@import '../../template/fx/icomoon/style.css';
@import '../../template/css/li_imagem.css';
@import '../../template/css/alerts'; // SCSS -> não leva extensão
@import '../../plugins/mixitup/css/mixitup2.css';
@import '../../template/css/owl-carousel/owl.carousel.css';
@import '../../template/css/owl-carousel/owl.theme.css';
@import '../../template/css/carousel_vertical.css';
@import '../../plugins/select2/select2.css';
@import '../../plugins/filepicker2/css/fileinput.css';
@import '../../plugins/daterangepicker/daterangepicker-bs3.css';
@import '../../plugins/toastr/build/toastr.min.css';
