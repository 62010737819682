$color-alert-bg: #e6cf59;
$color-alert-font: #706634;
$color-alert-font-disabled: #b1b1b1;
$color-alert-left-bg: #e3a754;
$color-alert-left-font: #fff;

.alert-slider {
    background-color: $color-alert-bg;
    color: $color-alert-font;
    height: 30px;
    position: relative;
    width: 100%;
    
    a {
        color: $color-alert-font;
        
        &:hover {
            text-decoration: underline;
        }
    }
    
    .alert-slider-alert {
        background-color: $color-alert-left-bg;
        color: $color-alert-left-font;
        float: left;
        font-size: .8em;
        font-weight: bold;
        height: 30px;
        margin-left: -5px;
        padding-top: 5px;
        text-align: center;
        width: 80px;
        
        i {
            vertical-align: middle;
        }
    }
    
    .alert-slider-message {
        padding-top: 4px;
        text-align: center;
        width: 100%;
        
        .unactive {
            display: none;
        }
        
        .active {
            animation: fadeIn .5s linear;
        }
    }
    
    .alert-slider-arrows {
        background-color: $color-alert-bg;
        float: right;
        height: 30px;
        margin-right: -35px;
        padding-top: 5px;
        width: 50px;
        
        i {
            cursor: pointer;
            z-index: 999;
        }
        
        .disabled {
            color: $color-alert-font-disabled;
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.alert-slider-spacer {
    margin-top: 30px;
}
